import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaBusinessTime, FaClock, FaDollarSign, FaCalendarAlt } from 'react-icons/fa';
import bg1 from '../assets/gallery/gallery54.jpg';
import bg2 from '../assets/b2.jpg';
import Reviews from '../components/Reviews';

const testimonials = [
  {
    text: "Amer installed my TV over the fireplace in my house and did a wonderful job. He made sure everything was perfect before he left and was great to work with. The price was great and so was his workmanship. I would easily recommend.",
    name: "Dylan K",
    role: "Through Google",
    image: "https://lh3.googleusercontent.com/a/ACg8ocINKPQgOiEhpNqlHXjkD6EVgu1INADJisBLVd-unSE0RiPFsA=w36-h36-p-rp-mo-br100"
  },
  {
    text: "I am so happy we found Stud TV Installs! From my first inquiry, they were responsive and incredibly friendly. After having 2 different companies came out and both recommending a large retailer for installation... I was getting discouraged that no one would take on our installation due to the tile over the fireplace. After reviewing their customer photos, I messaged them in the morning and by the afternoon, I had a quote and their availability to make an appointment. Because of our high mantel, we went with a specific pull-down mount. Prior to install, they reassured me they could complete the job and boy did they deliver! The install took about 1.5 hours which was extremely quick given the number of parts in the mount box! After install, the gentleman took the time to show my husband and I how to use the mount and its features. After seeing a little bit of a struggle, he loosened it and ensured it was to our liking. He also cleaned the entire area so well. Overall, I would highly recommend their service. They are extremely friendly, responsive and do a fantastic job!",
    name: "Daisy S",
    role: "Through Google",
    image: "https://lh3.googleusercontent.com/a/ACg8ocIXMigw8ouzSyUb1NLF_4InOmwWKiSWcz6dBo79eVs136F6Sw=w36-h36-p-rp-mo-br100"
  },
  {
    text: "Responded to our request straight away, turned up and did a really good job of installing a tv arm and mounting a large tv. Needed to do extra work and called promptly a couple of days later to do the extra work. Would recommend this company.",
    name: "Marion W",
    role: "From Porch.com",
    image: "https://th.bing.com/th/id/OIP.YRHX1IyFHBasS7DsdHnD_QHaJv?rs=1&pid=ImgDetMain"
  },
  {
    text: "The installation was great. My electrical outlet was in the wrong place, and the team came prepared with all of the appropriate equipment to make the adjustment and hang my tv on the wall quickly and efficiently. The team focused on every detail and spent extra time ensuring that the tv was level on the wall. I really appreciated the hard work and a perfect job completed.",
    name: "Matt C",
    role: "From Porch.com",
    image: "https://th.bing.com/th/id/OIP.YRHX1IyFHBasS7DsdHnD_QHaJv?rs=1&pid=ImgDetMain"
  },
  {
    text: "Very friendly and professional. Arrived in the arranged time window, did a very good installation, played with our psycho-kitty and departed. I especially liked his trick of taping a bag to the wall to catch the drywall dust from the drill.",
    name: "Morgan G",
    role: "From Amazon.com Seller Review",
    image: "https://th.bing.com/th/id/OIP.YRHX1IyFHBasS7DsdHnD_QHaJv?rs=1&pid=ImgDetMain"
  }
];

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="text-center" style={{ fontFamily: 'DM Sans' }}>
      <motion.div
        className="relative h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${bg1})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center sm:items-start sm:pl-14 sm:pr-10 sm:py-20">
          <h1 className="text-white text-6xl font-bold text-center sm:text-left mr-2" style={{ textShadow: '2px 2px 4px rgba(255, 255, 255, 0.7)', paddingBottom: '20px' }}>
            Stud TV Installs
          </h1>
          <h3 className="text-white text-2xl pr-2 mb-4 text-center sm:text-left" style={{ textShadow: '2px 2px 4px rgba(255, 255, 255, 0.7)', paddingBottom: '20px', maxWidth: '90%' }}>
            Offering Proffesional TV Installation in the Greater Seattle Area. We have many years of experience and fair prices, which guarantees customer satisfaction.
          </h3>
          <motion.div
            whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
            className="inline-block mb-4 text-center sm:text-left"
          >
            <a href="tel:+14254959981" className="bg-blue-500 text-white px-4 py-2 rounded font-bold flex items-center space-x-1 text-xl">
              <span>📞</span><span>(425) 495-9981</span>
            </a>
          </motion.div>
        </div>
      </motion.div>

      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col pb-6">
          <h3 className="text-white text-3xl font-bold px-4 mt-8 text-center" style={{ textShadow: '2px 2px 4px rgba(255, 255, 255, 0.7)' }}>
            Why Choose Us?
          </h3>
          <div className="mt-4 space-y-4">
            <p className="text-white text-lg px-4 text-center" style={{ textShadow: '2px 2px 4px rgba(255, 255, 255, 0.7)' }}>
              <FaClock className="inline-block w-6 h-6 mr-2 text-white" />
              10+ years of experience in the industry.
            </p>
            <p className="text-white text-lg px-4 text-center" style={{ textShadow: '2px 2px 4px rgba(255, 255, 255, 0.7)' }}>
              <FaDollarSign className="inline-block w-6 h-6 mr-1 text-white" />
              No Hidden Fees or Extra Costs.
            </p>
            <p className="text-white text-lg px-4 text-center" style={{ textShadow: '2px 2px 4px rgba(255, 255, 255, 0.7)' }}>
              <FaCalendarAlt className="inline-block w-6 h-6 mr-2 text-white " />
              Flexible Scheduling.
            </p>
            <p className="text-white text-lg px-4 text-center" style={{ textShadow: '2px 2px 4px rgba(255, 255, 255, 0.7)' }}>
              <FaBusinessTime className="inline-block w-6 h-6 mr-2 text-white" />
              We show up on time.
            </p>
          </div>
          <div className="mt-8 space-x-4 flex flex-wrap justify-center">
            <motion.div
              whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
              className="inline-block mb-4"
            >
              <Link to="/quote" className="bg-blue-500 text-white px-4 py-2 rounded font-bold" >
                <span>📺</span>Free Quote
              </Link>
            </motion.div>
          </div>
        </div>
      </motion.div>
      {/* <section className="bg-white dark:bg-gray-900" >
        <div className="container px-6 py-10 mx-auto">
          <h1 className="text-2xl font-semibold text-center text-black capitalize lg:text-3xl dark:text-white">
            What clients are saying
          </h1>

          <div className="flex justify-center mx-auto mt-6">
            <span className="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
            <span className="inline-block w-3 h-1 mx-1 bg-blue-500 rounded-full"></span>
            <span className="inline-block w-1 h-1 bg-blue-500 rounded-full"></span>
          </div>

          <div className="flex items-start max-w-6xl mx-auto mt-8">
            <button
              onClick={handlePrev}
              title="left arrow"
              className="p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 lg:block hover:bg-gray-100"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
              </svg>
            </button>

            <div>
              <p className="flex items-center text-center text-gray-500 lg:mx-8 text-sm md:text-base lg:text-lg">
                {testimonials[currentIndex].text}
              </p>

              <div className="flex flex-col items-center justify-center mt-8">
                <img className="object-cover rounded-full w-14 h-14 md:w-20 md:h-20 lg:w-24 lg:h-24" src={testimonials[currentIndex].image} alt={testimonials[currentIndex].name} />

                <div className="mt-4 text-center">
                  <h1 className="font-semibold text-gray-800 dark:text-white text-sm md:text-base lg:text-lg">{testimonials[currentIndex].name}</h1>
                  <span className="text-xs text-gray-500 dark:text-gray-400 md:text-sm lg:text-base">{testimonials[currentIndex].role}</span>
                </div>
              </div>
            </div>

            <button
              onClick={handleNext}
              title="right arrow"
              className="p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 lg:block hover:bg-gray-100"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
        <img src={reviews} alt="reviews" className="mx-auto w-4/5 pl-6 pb-4 sm:w-1/3 object-cover " />
      </section> */}
      <Reviews></Reviews>
    </div>

  );
};

export default Home;
