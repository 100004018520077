import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import bg4 from "../../assets/back4.png";

const InWallCableConcealment = () => {
  return (
    <div className="text-center">
      <motion.div
        className="relative h-64 md:h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bg4})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center">
          <h1 className="text-white text-2xl md:text-4xl font-bold mb-4">
            In-Wall Cable Concealment- Seattle
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <Link
              to="/quote"
              className="bg-blue-500 text-white px-4 py-2 rounded font-bold"
            >
              ↗️ Free Quote
            </Link>
          </motion.div>
        </div>
      </motion.div>
      <div className="max-w-xl mx-auto text-black px-4 pt-6 pb-10">
        <p className="text-base md:text-lg leading-relaxed mb-4">
          Tired of unsightly cables cluttering your living space? At Stud TV
          Installs our in-wall cable concealment services are the perfect
          solution to hide those pesky wires and keep your home looking sleek
          and organized. At Stud TV Installs, we specialize in professional
          in-wall cable management, ensuring that all your cables are neatly
          tucked away behind the walls for a clean and polished finish.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          We have years of experience in safely and effectively concealing
          cables within walls. Whether you're setting up a home theater system,
          mounting a TV, or simply want to tidy up your entertainment center,
          our in-wall cable concealment services provide a seamless solution. We
          work with all types of cables, including HDMI, power cords, Ethernet
          cables, and more, to ensure that your setup is both functional and
          aesthetically pleasing.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          Seattle homes come in all shapes and sizes, and we understand that
          each installation requires a customized approach. Our technicians
          carefully assess your space, taking into consideration wall types,
          stud placement, and electrical safety. We then create a tailored plan
          to conceal your cables without damaging your walls or compromising the
          integrity of your home. The result? A clutter-free environment where
          your technology works seamlessly with your interior design.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          Why is in-wall cable concealment important? Not only does it enhance
          the appearance of your home, but it also protects your cables from
          wear and tear, reducing the risk of damage over time. Additionally, by
          keeping cables hidden, you minimize the risk of accidents and improve
          the overall safety of your living space, especially in homes with
          children and pets.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          At Stud TV Installs, we are committed to delivering high-quality
          in-wall cable concealment services at competitive prices. Our process
          is straightforward and efficient, with minimal disruption to your
          daily routine. We use only the best tools and materials, ensuring that
          your concealed cables will stand the test of time. Plus, we offer a
          free quote, so you can get started on your project with confidence.
        </p>
        <p className="text-base md:text-lg leading-relaxed">
          Don’t let messy cables ruin the look of your home. Contact us today to
          schedule your in-wall cable concealment service in Seattle. Whether
          you're in Seattle, Bellevue, Redmond, Duvall, Snoqualmie, Kirkland or
          any other neighborhood, our team is ready to help you achieve a clean
          and sophisticated setup. Click the link below to request your free
          quote and take the first step towards a more organized home!
        </p>
      </div>
      <motion.div
        whileHover={{
          scale: 1.1,
          translateZ: 50,
          perspective: 1000,
          boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
          transition: { duration: 0.2 },
        }}
        className="inline-block mb-4"
      >
        <Link
          to="/quote"
          className="bg-blue-500 text-white px-4 py-2 rounded font-bold"
        >
          ↗️ Free Quote
        </Link>
      </motion.div>
    </div>
  );
};

export default InWallCableConcealment;
