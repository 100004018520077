import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import bg4 from "../../assets/back4.png";

const Mounting = () => {
  return (
    <div className="text-center">
      <motion.div
        className="relative h-64 md:h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bg4})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center">
          <h1 className="text-white text-2xl md:text-4xl font-bold mb-4">
            Stud TV Installs Services
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <Link
              to="/quote"
              className="bg-blue-500 text-white px-4 py-2 rounded font-bold"
            >
              ↗️ Free Quote
            </Link>
          </motion.div>
        </div>
      </motion.div>
      <div className="max-w-xl mx-auto text-black px-4 pt-6 pb-10">
        <p className="text-base md:text-lg leading-relaxed mb-4">
          Are you in search of professional and reliable TV mounting services in
          Seattle? Look no further! Stud TV Installs is here to provide
          top-notch TV installation services tailored to meet your specific
          needs. Whether you're looking to mount a small TV in your bedroom or a
          large flat-screen TV in your living room, we have the expertise and
          tools to ensure a seamless and secure installation.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          At Stud TV Installs, we understand the unique challenges that come
          with TV installation in Seattle homes and apartments. We have loads of
          experience ensure we can handle various wall types, including drywall,
          brick, concrete, and more. We carefully assess your space to determine
          the best placement for your TV, taking into consideration factors like
          viewing angles, room lighting, and furniture arrangement to create the
          perfect entertainment setup.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          Our TV mounting services in Seattle go beyond just installation. We
          also offer a range of additional services to enhance your viewing
          experience, including cable management, soundbar installation, and
          home theater setup. Our goal is to provide a clean, organized, and
          aesthetically pleasing setup that complements your home’s interior
          design.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          Why choose us? With years of experience in the industry, we pride
          ourselves on delivering exceptional customer service and high-quality
          workmanship. Our team is committed to ensuring your complete
          satisfaction, from the initial consultation to the final installation.
          We use only the best mounting brackets and equipment to guarantee the
          safety and stability of your TV. Plus, we offer competitive pricing
          and free quotes, making us the go-to choice for TV mounting in
          Seattle.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          Don’t risk damaging your walls or your TV with DIY mounting. Trust the
          professionals at Stud TV Installs to do the job right. Whether you're
          in Seattle, Bellevue, Redmond, Duvall, Snoqualmie, Kirkland or any other neighborhood,
          we’re ready to serve you. Contact us today to schedule your TV
          mounting service and take the first step towards a better home
          entertainment experience.
        </p>
        <p className="text-base md:text-lg leading-relaxed">
          Experience the difference with Seattle’s most trusted TV mounting
          service- Stud TV Installs. Our commitment to quality, safety, and
          customer satisfaction sets us apart. Ready to get started? Click the
          link below to request your free quote today!
        </p>
      </div>
      <motion.div
        whileHover={{
          scale: 1.1,
          translateZ: 50,
          perspective: 1000,
          boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
          transition: { duration: 0.2 },
        }}
        className="inline-block mb-4"
      >
        <Link
          to="/quote"
          className="bg-blue-500 text-white px-4 py-2 rounded font-bold"
        >
          ↗️ Free Quote
        </Link>
      </motion.div>
    </div>
  );
};

export default Mounting;
