import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import bgSpeakers from "../../assets/back1.png"; 
const FlushMountSpeakers = () => {
  return (
    <div className="text-center">
      <motion.div
        className="relative h-64 md:h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgSpeakers})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center">
          <h1 className="text-white text-2xl md:text-4xl font-bold mb-4">
            Flush Mount/In-Wall Speakers - Seattle
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <Link
              to="/quote"
              className="bg-blue-500 text-white px-4 py-2 rounded font-bold"
            >
              ↗️ Free Quote
            </Link>
          </motion.div>
        </div>
      </motion.div>
      <div className="max-w-xl mx-auto text-black px-4 pt-6 pb-10">
        <p className="text-base md:text-lg leading-relaxed mb-4">
          Experience immersive sound without the clutter. Our Flush Mount and In-Wall Speakers offer a sleek and modern solution for your home audio needs. Seamlessly integrate high-quality speakers into your walls or ceiling, providing exceptional sound without sacrificing your room’s aesthetics.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          At Stud TV Installs, we specialize in professional installation of flush mount and in-wall speakers. Whether you're creating a home theater or enhancing your living space with high-fidelity audio, our team ensures a precise and clean installation. No more bulky speakers or tangled wires—just pure, unobstructed sound.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          Our experts will work with you to choose the best speaker placement for optimal sound quality, taking into consideration room acoustics, wall types, and your specific audio preferences. We handle all aspects of the installation, including cutting into walls or ceilings, running wires, and securely mounting the speakers.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          Flush mount and in-wall speakers are perfect for any room, whether it’s your living room, home theater, or outdoor patio. They provide a clean, modern look while delivering superior sound performance. Plus, they blend seamlessly into your home’s design, making them an excellent choice for those who value both style and substance.
        </p>
        <p className="text-base md:text-lg leading-relaxed mb-4">
          Our Seattle-based team is dedicated to providing top-notch installation services. We take pride in our attention to detail and commitment to customer satisfaction. From initial consultation to final testing, we ensure that your new speakers are installed perfectly and that you’re thrilled with the results.
        </p>
        <p className="text-base md:text-lg leading-relaxed">
          Ready to upgrade your audio experience? Contact us today to schedule your flush mount or in-wall speaker installation. We serve Seattle and the surrounding areas, including Bellevue, Redmond, Kirkland, and more. Click the link below to request your free quote and start enjoying high-quality sound in your home.
        </p>
      </div>
      <motion.div
        whileHover={{
          scale: 1.1,
          translateZ: 50,
          perspective: 1000,
          boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
          transition: { duration: 0.2 },
        }}
        className="inline-block mb-4"
      >
        <Link
          to="/quote"
          className="bg-blue-500 text-white px-4 py-2 rounded font-bold"
        >
          ↗️ Free Quote
        </Link>
      </motion.div>
    </div>
  );
};

export default FlushMountSpeakers;
